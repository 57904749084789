<template>
  <v-container>
    <v-card>
    <h1 class="text-center mt-5 mb-10" >Fusion de Pdf</h1>
      <v-row :no-gutters="true" class="my-5">
        <v-col cols="6" class="d-flex justify-center align-center align-content-center">
          <v-text-field :clearable="true" v-model="titleInput" hide-details="true" label="Nom du fichier final" class="ml-4"></v-text-field>
        </v-col>
        <v-col cols="6" class="d-flex justify-center align-center align-content-center">
          <v-text-field :clearable="true" v-model="authorInput" hide-details="true" label="Auteur du fichier final" class="mx-4"></v-text-field>
        </v-col>
      </v-row>
    <div v-for="(input, index) in inputs" :key="index">
      <v-row :no-gutters="true" class="my-2">
        <v-col cols="10">
          <span class="ml-4">Fichier {{index + 1}}</span> <v-file-input class="ml-4" prepend-inner-icon="mdi-paperclip" prepend-icon="" hide-details="true" :ref="'fileInput' + index" clearable label="File input" @change="addFile(index)"></v-file-input>
        </v-col>
        <v-col cols="2" class="d-flex justify-center align-center align-content-center">
          <v-btn @click="removeInput(index)" color="red" icon="mdi-close" :disabled="inputs.length <= 2"></v-btn>
        </v-col>
      </v-row>
    </div>
      <v-row :no-gutters="true" class="my-5">
        <v-col cols="12" class="d-flex justify-center align-center align-content-center">
          <v-btn @click="addInput" class="mr-5" color="green-darken-1" prepend-icon="mdi-plus">Ajouter un fichier</v-btn>
          <v-btn @click="mergePDFs" color="blue-grey-darken-4" prepend-icon="mdi-merge">Fusionner les PDF</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import PDFMerger from 'pdf-merger-js';

export default {
  name: "PdfMerge",
  data() {
    return {
      inputs: [{files: [] }, {files: [] }],
      authorInput: null,
      titleInput: null,
    };
  },
  methods: {
    addFile(index) {
      const fileList = this.$refs['fileInput' + index][0].files;
      if (fileList.length > 0) {
        const pdfFiles = Array.from(fileList).filter(file => file.type === 'application/pdf');
        if (pdfFiles.length === fileList.length) {
          this.inputs[index].files = pdfFiles;
        } else {
          alert("Veuillez sélectionner uniquement des fichiers PDF.");
          this.$refs['fileInput' + index][0].value = "";
          this.inputs[index].files = [];
        }
      }
    },
    addInput() {
      this.inputs.push({ files: [] });
    },
    removeInput(index) {
      this.inputs.splice(index, 1);
    },
    async mergePDFs() {
      const merger = new PDFMerger();
      try {
        for (let i = 0; i < this.inputs.length; i++) {
          const input = this.inputs[i];
          for (let j = 0; j < input.files.length; j++) {
            await merger.add(input.files[j]);
          }
        }
        await merger.setMetadata({
          producer: this.authorInput,
          author: this.authorInput,
          creator: this.authorInput,
          title: this.titleInput
        });
        await merger.save(this.titleInput);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>