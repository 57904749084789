<template>
  <v-container>
    <h1 class="text-center mt-5 mb-10" >Générateur de QR Code</h1>
    <v-row :no-gutters="false">
      <v-col cols="12" md="6" class="justify-center d-flex align-center align-content-center">
        <v-row :no-gutters="false">
          <v-col cols="12">
            <p class="text-center mb-2">Url du QR Code :</p>
            <v-text-field label="Renseigner l'url pour le QR Code" v-model="urlQrCode" variant="solo"></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <p class="text-center mb-2">Couleur de fond QR Code :</p>
            <v-color-picker class="mx-auto w-75" v-model="backgroundQrCode" hide-inputs></v-color-picker>
          </v-col>
          <v-col cols="12" lg="6">
            <p class="text-center mb-2">Couleur premier plan du QR Code :</p>
            <v-color-picker class="mx-auto w-75" v-model="foregroundQrCode" hide-inputs></v-color-picker>
          </v-col>
          <v-col cols="12">
            <p class="text-center">Taille du QR Code :</p>
            <v-slider
                track-color="white"
                thumb-color="blue-grey-lighten-3"
                track-fill-color="blue-grey-darken-3"
                class="w-100"
                :max="500"
                :min="100"
                v-model="sizeQrCode"
            ></v-slider>
          </v-col>
          <v-col cols="12">
            <p class="text-center mb-2">Nom QR Code :</p>
            <v-text-field label="Renseigner un nom pour le Qrcode" v-model="nameQrcode" variant="solo"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="position-relative flex-column justify-center d-flex align-center align-content-center">
        <p class="mb-7">Aperçu du QR Code :</p>
        <qrcode-vue id="test" :value="urlQrCode" :size="sizeQrCode" level="H" :background="backgroundQrCode" :foreground="foregroundQrCode"/>
      </v-col>
      <v-col cols="12" class="d-flex justify-center align-content-center align-center">
        <v-btn @click="testeur" color="blue-grey-darken-4" size="x-large">Télécharger le QR-CODE</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {defineComponent} from 'vue';
import QrcodeVue from "qrcode.vue";
export default defineComponent({
  name: 'QRCodeView',
  components: {QrcodeVue},
  data() {
    return {
      sizeQrCode: 400,
      backgroundQrCode: "#ffffff",
      foregroundQrCode: "#000000",
      urlQrCode: null,
      nameQrcode: "canvas_generator_ville_plurielle"
    }
  },
  methods: {
    testeur() {
      const canvas = document.getElementById('test');
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = this.nameQrcode;
      link.click();
    },
  }
});
</script>