<template>
    <v-snackbar :style="{'margin-bottom':calcMargin(i)}" v-for="(snackbar,i) in snackbars" :key="snackbar.id" v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color" elevation="24">
      <p @click="hideSnackbar(snackbar.id)" class="text-center">{{ snackbar.message }}</p>
      <template v-slot:actions>
        <v-btn
            icon="mdi-close"
            color="white"
            density="comfortable"
            size="x-small"
            @click="hideSnackbar(snackbar.id)"
        ></v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import { defineComponent } from 'vue';
import { generalStore } from "@/store/General/generalStore";

export default defineComponent({
  name: 'SnackbarComponent',
  computed: {
    snackbars() {
      return generalStore().snackbars;
    },
  },

  methods: {
    calcMargin(i) {
      return (i*60) + 'px'
    },
    hideSnackbar(id) {
      generalStore().hideSnackbar(id);
    },
  },
});
</script>