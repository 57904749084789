<template>
  <v-main class="w-100">
    <v-carousel class="text-black w-100" hide-delimiter-background show-arrows="hover" color="white" cycle="true">
      <v-carousel-item src="https://www.ville-plurielle.fr/images/Ville-pluirielle-le-siege.png" cover>
        <div class="d-flex fill-height justify-center align-center">
          <div class="text-h2 text-center text-white w-50" style="background-color: rgba(0,0,0,0.7);">
            Bienvenue sur <br>VPConnect
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-main>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'HomeView',
});
</script>
