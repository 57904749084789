<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" sm="8" md="4">
        <v-card color="blue-grey-darken-4">
          <v-card-title class="headline text-center mb-2">Accéder à VPConnect</v-card-title>
          <v-row justify="center" class="my-2">
            <v-img :src="require('@/assets/logo-vpconnect.png')" alt="Logo" max-height="100" contain></v-img>
          </v-row>
          <v-card-text>
            <v-form>
              <v-text-field
                  v-model="username"
                  label="Username"
                  prepend-inner-icon="mdi-account"
                  class="bg-blue-grey-lighten-5 rounded rounded-b-0"
                  hide-details
              ></v-text-field>

              <v-text-field
                  v-model="password"
                  label="Password"
                  prepend-inner-icon="mdi-lock"
                  class="bg-blue-grey-lighten-5 rounded rounded-t-0"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  hide-details
                  @click:append-inner="showPassword = !showPassword"
              ></v-text-field>
              <p class="text-right mt-1"><a href="test">Mot de passe oublié</a></p>
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex justify-center mt-0 mb-0">

            <v-btn
                class="px-5 mt-0 mb-0"
                color="blue-grey-lighten-5"
                size="x-large"
                variant="flat"
                   @click="login()"
            >Se connecter</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';
import {authStore} from "@/store/General/authStore";
import {generalStore} from "@/store/General/generalStore";

export default defineComponent({
  name: 'LoginView',
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
    };
  },
  methods: {
    async login() {
      try {
        const response = await authStore().apiLogin(this.username, this.password);
        if (response) {
          this.$router.push('/accueil');
        }
      } catch (error) {
        generalStore().showNotifSnackbar("Une erreur est survenue lors de la connexion !", "red", true, 5000);
      }
    },
  },
});
</script>