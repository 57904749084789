<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table-virtual class="px-4" :headers="headersRegistration" :items="filteredRegistration" items-per-page="-1">
          <template v-slot:top>
            <v-row :no-gutters="true">
              <v-col cols="12" class="mt-4 d-flex justify-space-between align-content-center align-center">
                <v-btn prepend-icon="mdi-arrow-left-bold" @click="goTo('/inscription/fiche')" class="mr-2" color="blue-grey-darken-4" variant="flat">Retour Fiches</v-btn>
              </v-col>
              <v-col cols="12" class="mt-4">
                <v-card color="orange" class="text-white">
                  <v-card-text class="d-flex flex-row justify-space-between align-content-center align-center">
                    <div class="d-flex flex-column">
                      <p class="text-h4">{{ getSheetId.lastname }} {{ getSheetId.firstname }}</p>
                      <p class="text-h6" v-if="getSheetId.birthday != null"><v-icon icon="mdi-cake-variant"></v-icon> {{ formatDateShow(getSheetId.birthday) }} ({{calculateAge(getSheetId.birthday)}} ans)</p>
                      <p v-else class="text-h6"><v-icon icon="mdi-cake-variant"></v-icon> Non renseigné</p>
                    </div>
                    <div class="d-flex flex-row align-content-center align-center">
                      <v-icon size="x-large" icon="mdi-phone" class="mr-4"></v-icon>
                      <p v-if="getSheetId.phone != null" class="text-h6">{{ getSheetId.phone }}</p>
                      <p v-else class="text-h6">Non renseigné</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" class="my-4">
                <v-card color="blue">
                  <v-card-text class="d-flex flex-md-row flex-column justify-center justify-md-start align-content-center align-center">
                    <v-icon size="x-large" icon="mdi-pencil" class="mr-0 mr-md-4"></v-icon>
                    <div class="d-flex flex-column">
                      <p class="text-h6 text-center text-md-left">Total d'Inscription</p>
                      <p class="text-h4 text-center text-md-left">{{ getSheetId.totalRegistration }}</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" class="my-0 pl-0 my-md-4 mb-4 mb-md-0 pl-md-4">
                <v-card color="red">
                  <v-card-text class="d-flex flex-md-row flex-column justify-center justify-md-start align-content-center align-center">
                    <v-icon size="x-large" icon="mdi-currency-eur" class="mr-0 mr-md-4"></v-icon>
                    <div class="d-flex flex-column">
                      <p class="text-h6 text-center text-md-left">Total Dépenses</p>
                      <p class="text-h4 text-center text-md-left">{{ getSheetId.priceTotal }}€</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" class="my-0 pl-0 my-md-4 mb-4 mb-md-0 pl-md-4">
                <v-card color="green">
                  <v-card-text class="d-flex flex-md-row flex-column justify-center justify-md-start align-content-center align-center">
                    <v-icon size="x-large" icon="mdi-calendar-account-outline" class="mr-0 mr-md-4"></v-icon>
                    <div class="d-flex flex-column">
                      <p class="text-h6 text-center text-md-left">Dernière Activité</p>
                      <p v-if="getSheetId.latestRegistration != null" class="text-h4 text-center text-md-left text-truncate" style="max-width: 300px">{{ getSheetId.latestRegistration }}</p>
                      <p v-else class="text-h4 text-center text-md-left text-truncate" style="max-width: 300px">Aucune</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" class="my-0 pl-0 mb-4 mb-md-0">
                <v-text-field v-model="searchRegistration" prepend-inner-icon="mdi-magnify" label="Rechercher une inscription" single-line hide-details :clearable="true"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex flex-column d-md-flex flex-md-row justify-center align-content-center align-center">
              <v-chip v-if="isDateBetween(item.dateActivityStart, item.dateActivityEnd)" color="orange-darken-1" size="small">En cours</v-chip>
              <v-chip v-else-if="isDateAfter(item.dateActivityEnd)" color="green-darken-1" size="small">Terminé</v-chip>
              <v-chip v-else color="blue-darken-1" size="small">A venir</v-chip>
            </div>
          </template>
          <template v-slot:[`item.payement`]="{ item }">
            <v-checkbox class="d-flex justify-center align-content-center align-center" @input="callChangePaid(item)" hide-details v-model="item.payement"></v-checkbox>
          </template>
          <template v-slot:[`item.tools`]="{ item }">
            <v-btn icon="mdi-close-box-outline" density="comfortable" color="red-darken-1" @click="removeChildEvent(item.idRegistration)"></v-btn>
          </template>
          <template v-slot:no-data>
            <v-empty-state
                icon="mdi-magnify"
                text="Essayer d'inscrire cette fiche dans des activités ou bien de rafraîchir si vous venez d'inscrire cette fiche à une activité."
                title="Nous n'avons trouvé aucune Inscription."
            >
              <template v-slot:actions>
                <v-btn @click="callGetRegistration()" prepend-icon="mdi-refresh" class="mr-2" color="blue-grey-darken-4" variant="flat">Rafraîchir</v-btn>
              </template>
            </v-empty-state>
          </template>
        </v-data-table-virtual>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {apiRegistrationStore} from "@/store/Registration/apiRegistrationStore";
import {generalStore} from "@/store/General/generalStore";
export default {
  name: 'SheetInfoView',
  data() {
    return {
      searchSheet: null,
      selectedSheet: [],
      dialogSheetList: false,
      headersRegistration: [
        { title: 'Status', key: 'status', align: 'center', sortable: true},
        { title: 'Activité', key: 'name', align: 'center', sortable: true},
        { title: "Date de l'inscription", align: 'center', key: 'createdAt', sortable: true},
        { title: 'Payé', align: 'center', key: 'payement', sortable: false},
        { title: '', align: 'center', key: 'tools', sortable: false},
      ],
      searchRegistration: null
    }
  },
  computed: {
    filteredRegistration() {
      const searchTerm = this.searchRegistration ? this.searchRegistration.toLowerCase() : '';
      if (Array.isArray(apiRegistrationStore().getSheetId.registration)) {
        return apiRegistrationStore().getSheetId.registration.filter(event =>
            event.name.toLowerCase().includes(searchTerm)
        );
      } else {
        return [];
      }
    },
    getSheetId() {
      return apiRegistrationStore().getSheetId;
    }
  },
  methods: {
    calculateAge(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    formatDateShow(dateString) {
      const options = {day: 'numeric', month: 'numeric', year: 'numeric'};
      const date = new Date(dateString);
      return date.toLocaleString('fr-FR', options);
    },
    formatHour(dateString) {
      const options = {hour: 'numeric', minute: 'numeric'};
      const date = new Date(dateString);
      return date.toLocaleString('fr-FR', options);
    },
    async callGetRegistration() {
      try {
        const response = await apiRegistrationStore().callGetSheetId(this.$route.params.id);
        if (response) {
          generalStore().showNotifSnackbar("Actualisation de la fiche !", "green", true, 5000);
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    async callGetSheet() {
      try {
        const response = await apiRegistrationStore().callGetSheet();
        if (response) {
          this.dialogSheetList = true;
          generalStore().showNotifSnackbar("gg", "green", true, 5000);
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    async removeChildEvent(idRegistration) {
      try {
        const response = await apiRegistrationStore().callDeleteRegistration(idRegistration);
        if (response) {
          this.callGetRegistration();
          generalStore().showNotifSnackbar("Suppresion de l'inscription", "green", true, 5000);
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    goTo(url) {
      this.$router.push(url);
    },
    isDateBetween(startDate, endDate) {
      const currentDateMs = Date.now();
      const startDateMs = Date.parse(startDate);
      const endDateMs = Date.parse(endDate);
      return currentDateMs >= startDateMs && currentDateMs <= endDateMs;
    },
    isDateAfter(endDate) {
      const currentDateMs = Date.now();
      const endDateMs = Date.parse(endDate);
      return currentDateMs > endDateMs;
    },
    async callChangePaid(item) {
      try {
        const response = await apiRegistrationStore().callPutRegistration(item.idRegistration, item.payement);
        if (response) {
          generalStore().showNotifSnackbar("Modification paiement réussie !", "green", true, 5000);
          await this.callGetRegistration();
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
  }
};
</script>
<style scoped>
:deep(.v-checkbox) {
  min-height: 0;
}
</style>