<template>
  <v-container>
    <div>
      <div v-for="(entry, index) in entries" :key="index">
        <v-row>
          <v-col>
            <v-text-field v-model="entry.startTime" label="Start Time" type="time"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="entry.endTime" label="End Time" type="time"></v-text-field>
          </v-col>
          <v-col>
            <v-btn @click="removeEntry(index)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <p>Total Hours: {{ entryTotalHours(index) }}</p>
          </v-col>
        </v-row>
      </div>
      <v-btn @click="addEntry">Add Entry</v-btn>
    </div>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <p>Total Hours: {{ totalHours }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HourlyCalculationView',
  data() {
    return {
      entries: [{ startTime: '', endTime: '' }],
    };
  },
  computed: {
    totalHours() {
      let totalMinutes = 0;

      for (const entry of this.entries) {
        const start = entry.startTime.split(':');
        const end = entry.endTime.split(':');

        const startTime = parseInt(start[0]) * 60 + parseInt(start[1]);
        const endTime = parseInt(end[0]) * 60 + parseInt(end[1]);

        totalMinutes += endTime - startTime;
      }

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${hours} hours and ${minutes} minutes`;
    },
  },
  methods: {
    addEntry() {
      this.entries.push({ startTime: '', endTime: '' });
    },
    removeEntry(index) {
      this.entries.splice(index, 1);
    },
    entryTotalHours(index) {
      const entry = this.entries[index];
      const start = entry.startTime.split(':');
      const end = entry.endTime.split(':');

      const startTime = parseInt(start[0]) * 60 + parseInt(start[1]);
      const endTime = parseInt(end[0]) * 60 + parseInt(end[1]);

      const totalMinutes = endTime - startTime;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${hours} hours and ${minutes} minutes`;
    },
  },
});
</script>