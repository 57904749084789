<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" sm="8" md="4">
        <v-card color="blue-grey-darken-4">
          <v-card-title class="headline text-center mb-2">La page n'existe pas !</v-card-title>
          <v-card-text>
            <v-img :src="require('@/assets/logo-vpconnect-error404.png')" alt="Logo" max-height="100" contain></v-img>
          </v-card-text>
          <v-card-actions class="d-flex justify-center mt-0 mb-0">
            <v-btn @click="returnHomePage()">Accueil</v-btn>
            <v-btn @click="returnBackPage()">Revenir à la page d'avant</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';
import router from "@/router";

export default defineComponent({
  name: 'Error404View',
  methods: {
    returnHomePage() {
      router.push('/');
    },
    returnBackPage() {
      router.go(-1);
    }
  }
});
</script>