import {defineStore} from 'pinia'
import axios from "axios";
import router from "@/router";
import {generalStore} from "@/store/General/generalStore";

export const authStore = defineStore('authStore', {
    state: () => {
        return {
            connected: false,
            token: null
        }
    },
    getters: {
        getConnected: (state) => {
            if(state.token === null || state.token === "" || state.connected === false) {
                return false;
            }
            return true;
        },
        getToken: (state) => {
            return state.token
        },
    },
    actions: {
        async apiLogin(username, password) {
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_LOGIN, {
                    username: username,
                    password: password
                });
                generalStore().showNotifSnackbar("Connexion réussie !", "green", true, 5000);
                this.token = response.data.token;
                this.connected = true;
                return true;
            } catch (error) {
                handleApiError2(error);
                return false;
            }
        },
        async apiLogout() {
            try {
                this.token = null;
                this.connected = false;
                router.push("/");
            } catch (error) {
                console.error(error);
                return false;
            }
        },
    },
    persist: {
        storage: localStorage,
        paths: ['token', 'connected'],
    },
})

export function handleApiError2(error) {
    if (error.response) {
        if(error.response.status === 401) {
            generalStore().showNotifSnackbar("Erreur vérifier votre mot de passe ou username !", "red", true, 5000);
        } else {
            generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red", true, 5000);
        }
    } else if (error.request) {
        generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red", true, 5000);
    } else {
        generalStore().showNotifSnackbar("Erreur lors de la requête !", "red", true, 5000);
    }
}