<template>
  <section id="topbar">
    <v-app-bar color="blue-grey-darken-4" app elevation="0">
      <v-app-bar-nav-icon @click="toggleNavbar"><v-icon :icon="getNavbarShowIcon"></v-icon></v-app-bar-nav-icon>
      <v-toolbar-title>{{ this.$route.name }}</v-toolbar-title>
      <template v-slot:append>
        <v-menu transition="slide-y-transition">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-account" v-bind="props"></v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item prepend-icon="mdi-eye" title="Mon profil" class="bg-blue-grey-darken-4" @click="goTo('/profil')"></v-list-item>
            <v-list-item prepend-icon="mdi-logout" title="Se déconnecter" class="bg-red" @click="logout"></v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
  </section>
</template>

<script>
import {generalStore} from "@/store/General/generalStore";
import {authStore} from "@/store/General/authStore";

export default {
  name: 'TopbarComponent',
  computed: {
    getNavbarShowIcon() {
      return generalStore().getNavbarShowIcon;
    }
  },
  methods: {
    toggleNavbar() {
      generalStore().toogleNavbar();
    },
    logout() {
      authStore().apiLogout();
    },
    goTo(url) {
      this.$router.push(url);
    },
  }
}
</script>
