<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table-virtual :headers="headersRegistration" :items="filteredRegistration" items-per-page="-1">
          <template v-slot:top>
            <v-row class="pa-2 bg-grey-lighten-4" :no-gutters="true">
              <v-col cols="12" class="mb-2 d-flex justify-space-between align-content-center align-center">
                <v-btn prepend-icon="mdi-arrow-left-bold" @click="goTo('/inscription/activite')" class="mr-2" color="blue-grey-darken-4" variant="flat">Retour Activités</v-btn>
                <!--<v-breadcrumbs :items="['Foo', 'Bar', 'Fizz']"></v-breadcrumbs>-->
              </v-col>
              <v-col cols="4" class="mb-2">
                <p class="text-center text-h6">Inscrits</p>
                <div v-if="getRegistration.seat" class="fill-height d-flex justify-center align-center align-content-center flex-row">
                <span class="font-weight-bold" :class="{
                'text-red': getRegistration.seat - getRegistration.totalRegistration <= 0,
                'text-orange': getRegistration.seat - getRegistration.totalRegistration <= 3 & getRegistration.seat - getRegistration.totalRegistration > 0,
                'text-amber-lighten-2': getRegistration.seat - getRegistration.totalRegistration <= 5 && getRegistration.seat - getRegistration.totalRegistration > 3,
                'text-green': getRegistration.seat - getRegistration.totalRegistration > 5}"
                >
                {{ getRegistration.totalRegistration }}
              </span>
                  <span class="mx-1">/</span>
                  <span class="font-weight-bold">{{ getRegistration.seat }}</span>
                </div>
                <p v-else class="fill-height d-flex justify-center align-center align-content-center flex-row">{{ getRegistration.totalRegistration }}</p>
              </v-col>
              <v-col cols="4" class="mb-2">
                <p class="text-center text-h6">Prix</p>
                <div v-if="getRegistration.priceMember !== null || getRegistration.price > 0" class="fill-height d-flex justify-center align-center align-content-center flex-column">
                  <p class="text-center">{{ getRegistration.priceMember !== null ? getRegistration.priceMember + '€ (Adhérent)' : 'Gratuit (Adhérent)' }}</p>
                  <p class="text-center">{{ getRegistration.price !== null ? getRegistration.price + '€ (Non Adhérent)' : 'Gratuit (Non Adhérent)' }}</p>
                </div>
                <div v-else class="fill-height d-flex justify-center align-center align-content-center flex-column">
                  <p class="text-center">Gratuit</p>
                </div>
              </v-col>
              <v-col cols="4" class="mb-2">
                <p class="text-center text-h6">Dates</p>
                <div class="fill-height d-flex justify-center align-center align-content-center flex-column">
                  <p class="text-center">
                    {{ formatDateShow(getRegistration.dateActivityStart) }} à {{ formatHour(getRegistration.dateActivityStart) }}
                  </p>
                  <p class="text-center">au</p>
                  <p class="text-center">
                    {{ formatDateShow(getRegistration.dateActivityEnd) }} à {{ formatHour(getRegistration.dateActivityEnd) }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" class="my-6 d-flex justify-space-between align-content-center align-center">
                <v-btn prepend-icon="mdi-plus" @click="openSheetList()" class="mr-2" color="green-darken-1" variant="flat">Inscrire une personne</v-btn>
                <v-btn prepend-icon="mdi-creation" @click="goTo('/inscription/fiche/creation')" class="mr-2" color="green-darken-1" variant="flat">Créer une fiche</v-btn>
                <v-btn icon="mdi-refresh" @click="callGetRegistration()" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
                <v-btn icon="mdi-printer" @click="print" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" disabled="true"></v-btn>
                <v-btn icon="mdi-download" @click="download()" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
              </v-col>
              <v-col cols="12" class="mb-2 d-flex justify-space-between align-content-center align-center">
                <v-text-field v-model="searchRegistration" prepend-inner-icon="mdi-magnify" label="Rechercher une personne dans l'activité" single-line hide-details clearable="true"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.payement`]="{ item }">
            <v-checkbox @input="callChangePaid(item)" hide-details v-model="item.payement"></v-checkbox>
          </template>
          <template v-slot:[`item.tools`]="{ item }">
            <v-btn icon="mdi-close-box-outline" density="comfortable" color="red-darken-1" @click="removeChildEvent(item.id)"></v-btn>
          </template>
          <template v-slot:bottom></template>
        </v-data-table-virtual>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog
      width="auto"
      scrollable
      v-model="dialogSheetList"
  >
      <v-card
          prepend-icon="mdi-pencil"
          title="Sélectionner des fiches à inscrire"
      >
        <v-divider class="mt-3"></v-divider>
        <v-text-field v-model="searchSheet" prepend-inner-icon="mdi-magnify" label="Rechercher une personne" single-line hide-details clearable="true"></v-text-field>
        <v-card-text class="px-4" style="height: 300px;">
          <v-data-table-virtual :headers="headersSheet" :items="filteredSheet" items-per-page="-1">
            <template v-slot:[`item.select`]="{ item }">
              <v-checkbox hide-details v-model="selectedSheet" :value="item.id"></v-checkbox>
            </template>
            <template v-slot:bottom></template>
          </v-data-table-virtual>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn prepend-icon="mdi-close-thick" @click="dialogSheetList = false" color="red-darken-1" variant="flat">Annuler</v-btn>


          <v-spacer></v-spacer>

          <v-btn
              color="green"
              text="Ajouter"
              variant="flat"
              @click="addRegistration()"
          ></v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import {apiRegistrationStore} from "@/store/Registration/apiRegistrationStore";
import {generalStore} from "@/store/General/generalStore";
import html2pdf from 'html2pdf.js';
export default {
  name: 'RegistrationView',
  data() {
    return {
      searchSheet: null,
      selectedSheet: [],
      dialogSheetList: false,
      headersSheet: [
        { title: 'Sélectionner', align: 'start', sortable: false, key: 'select'},
        { title: 'Nom', align: 'start', sortable: true, key: 'lastname'},
        { title: 'Prénom', align: 'start', key: 'firstname', sortable: true},
        { title: 'Naissance', align: 'start', key: 'birthday', sortable: true},
        { title: 'Phone', align: 'start', key: 'phone', sortable: false},
      ],
      headersRegistration: [
        { title: 'Nom', align: 'start', sortable: true, key: 'lastname'},
        { title: 'Prénom', align: 'start', key: 'firstname', sortable: true},
        { title: 'Téléphone', align: 'start', key: 'phone', sortable: false},
        { title: 'Payé', align: 'start', key: 'payement', sortable: true},
        { title: '', align: 'start', key: 'tools', sortable: true},
      ],
      searchRegistration: null
    }
  },
  created() {
    this.callGetRegistration();
  },
  computed: {
    filteredSheet() {
      const searchTerm = this.searchSheet ? this.searchSheet.toLowerCase() : '';
      if (Array.isArray(apiRegistrationStore().getSheet)) {
        return apiRegistrationStore().getSheet.filter(event =>
            event.lastname.toLowerCase().includes(searchTerm)
        );
      } else {
        return [];
      }
    },
    filteredRegistration() {
      const searchTerm = this.searchRegistration ? this.searchRegistration.toLowerCase() : '';
      if (Array.isArray(apiRegistrationStore().getRegistration.registration)) {
        return apiRegistrationStore().getRegistration.registration.filter(event =>
            event.lastname.toLowerCase().includes(searchTerm)
        );
      } else {
        return [];
      }
    },
    getRegistration() {
      return apiRegistrationStore().getRegistration;
    }
  },
  methods: {
    formatDateShow(dateString) {
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleString('fr-FR', options);
    },
    formatHour(dateString) {
      const options = { hour: 'numeric', minute: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleString('fr-FR', options);
    },
    async callGetRegistration() {
      try {
        const response = await apiRegistrationStore().callGetRegistration(this.$route.params.id);
        if (response) {
          generalStore().showNotifSnackbar("Actualisation des inscriptions effectuées !", "green", true, 5000);
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    async callGetSheet() {
      try {
        const response = await apiRegistrationStore().callGetSheet();
        if (response) {
          this.dialogSheetList = true;
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    async addRegistration() {
      let selectedItems = Object.values(this.selectedSheet);
      let arrayResSheet = this.selectedSheet.map(id => {
        return {
          id: id,
          payement: false
        };
      })
      if (selectedItems.length > 0) {
        try {
          const response = await apiRegistrationStore().callPostRegistration(this.getRegistration.id, arrayResSheet);
          if (response) {
            generalStore().showNotifSnackbar("Ajout des fiches à l'activité réussie !", "green", true, 5000);
            this.callGetRegistration();
            this.dialogSheetList = false;
          }
        } catch (error) {
          console.error("Une erreur s'est produite", error);
        }
      }
    },
    goTo(url) {
      this.$router.push(url);
    },
    openSheetList() {
      this.callGetSheet();
    },
    async callChangePaid(item) {
      try {
        const response = await apiRegistrationStore().callPutRegistration(item.id, item.payement);
        if (response) {
          generalStore().showNotifSnackbar("Modification paiement réussie !", "green", true, 5000);
          this.callGetRegistration();
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    download() {
      this.searchRegistration = null;
      function formatNumberWithZero(number) {
        return number < 10 ? '0' + number : number;
      }
      function removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }
// Récupérer la date actuelle
      const dateActuelle = new Date(this.getRegistration.dateActivityStart);

// Formater la date
      const jour = formatNumberWithZero(dateActuelle.getDate());
      const mois = formatNumberWithZero(dateActuelle.getMonth() + 1); // Les mois sont indexés à partir de zéro
      const annee = dateActuelle.getFullYear();
      const heure = formatNumberWithZero(dateActuelle.getHours());
      const minute = formatNumberWithZero(dateActuelle.getMinutes());

// Construire la chaîne de date
      let dateFormatee = `le ${jour}/${mois}/${annee} à ${heure}h${minute}`;
      const table = document.createElement('table');
      table.innerHTML = `
        <caption style="margin: 20px">${this.getRegistration.name} ${dateFormatee}</caption>
        <thead>
          <tr>
          <th>#</th>
            ${this.headersRegistration.filter(header => header.title !== '').map(header => `<th>${header.title}</th>`).join('')}
          </tr>
        </thead>
        <tbody>
          ${this.filteredRegistration.map((item, index) => `
            <tr>
              <td>${index + 1}</td>
              <td>${item.lastname}</td>
              <td>${item.firstname}</td>
              <td>${item.phone ? item.phone : 'Aucun'}</td>
              <td style="height: 100%; display: flex; align-content: center; align-items: center; justify-content: center;"><input style="width: 25px; height: 50px; display: flex; align-content: center; align-items: center; justify-content: center;" type="checkbox" ${item.payement ? 'checked' : ''} disabled /></td>
              </tr>
          `).join('')}
        </tbody>
        <style>
        tr {
            border: 1px solid black;
        }
        </style>
      `;
      table.style.width = "100%";
      table.style.pageBreakInside = "avoid"
      table.style.borderCollapse = "collapse";
      table.style.textAlign = "center";
      table.style.fontSize = "30px";

      dateFormatee = `${jour}_${mois}_${annee}_${heure}_${minute}`;
// Supprimer les accents et remplacer les espaces par des underscores dans le nom de l'événement
      const nomEvenement = removeAccents(this.getRegistration.name.replace(/ /g, '_'));

// Nom du fichier PDF avec la date formatée et le nom de l'événement sans accents
      const nomFichierPDF = `${nomEvenement}_${dateFormatee}.pdf`;

// Générer le PDF avec le nom du fichier
      html2pdf().from(table).set({ format: 'A4' }).save(nomFichierPDF);
    },
    async removeChildEvent(idRegistration) {
      try {
        const response = await apiRegistrationStore().callDeleteRegistration(idRegistration);
        if (response) {
          this.callGetRegistration();
          generalStore().showNotifSnackbar("Suppresion de l'inscription", "green", true, 5000);
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    }
  }
};
</script>
<style scoped>
:deep(.v-checkbox) {
  min-height: 0;
}
</style>