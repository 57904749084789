<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="bg-grey-lighten-4">
          <v-container>
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between align-content-center align-center">
                <v-btn prepend-icon="mdi-arrow-left-bold" @click="goTo('/inscription/fiche')" class="mr-2" color="blue-grey-darken-4" variant="flat">Retour Fiches</v-btn>
                <!--<v-breadcrumbs :items="['Foo', 'Bar', 'Fizz']"></v-breadcrumbs>-->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    hide-details
                    :clearable="true"
                    v-model="lastnameInput" :error-messages="v$.lastnameInput.$errors.map(e => e.$message)" @input="v$.lastnameInput.$touch" @blur="v$.lastnameInput.$touch"
                    required
                >
                  <template v-slot:label>
                    <div class="custom-label">
                      Nom de la personne<span class="text-red-darken-1">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    hide-details
                    :clearable="true"
                    v-model="firstnameInput" :error-messages="v$.firstnameInput.$errors.map(e => e.$message)" @input="v$.firstnameInput.$touch" @blur="v$.firstnameInput.$touch"
                    required
                >
                  <template v-slot:label>
                    <div class="custom-label">
                      Prénom de la personne<span class="text-red-darken-1">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    hide-details
                    :clearable="true"
                    type="date"
                    v-model="birthdayInput" :error-messages="v$.birthdayInput.$errors.map(e => e.$message)" @input="v$.birthdayInput.$touch" @blur="v$.birthdayInput.$touch"
                    required
                >
                  <template v-slot:label>
                    <div class="custom-label">
                      Date de naissance de la personne<span class="text-red-darken-1">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    hide-details
                    :clearable="true"
                    v-model="phoneInput" :error-messages="v$.phoneInput.$errors.map(e => e.$message)" @input="v$.phoneInput.$touch" @blur="v$.phoneInput.$touch"
                    required
                >
                  <template v-slot:label>
                    <div class="custom-label">
                      Téléphone de la personne<span class="text-red-darken-1">*</span>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between align-content-center align-center">
                <v-btn prepend-icon="mdi-close-thick" @click="goTo('/inscription/fiche')" color="red-darken-1" variant="flat">Annuler</v-btn>
                <v-btn v-if="getSheetManageType === 0" @click="callPostSheet()" prepend-icon="mdi-creation" color="green-darken-1" variant="flat">Créer</v-btn>
                <v-btn v-if="getSheetManageType === 1" @click="callPutSheet()" prepend-icon="mdi-creation" color="green-darken-1" variant="flat">Modifier</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {helpers, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {apiRegistrationStore} from "@/store/Registration/apiRegistrationStore";
import {generalStore} from "@/store/General/generalStore";

export default {
  name: 'SheetManageView',
  data() {
    return {
      isMobile: false,
      lastnameInput: null,
      firstnameInput: null,
      birthdayInput: null,
      phoneInput: null,
    };
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      lastnameInput: {
        required: helpers.withMessage('Le nom est requis.', required)
      },
      firstnameInput: {
        required: helpers.withMessage('Le prénom est requis.', required)
      },
      birthdayInput: {
        required: helpers.withMessage('Le prénom est requis.', required)
      },
      phoneInput: {
        validPhoneNumber(value) {
          const frenchPhoneNumberRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
          if (value.match(frenchPhoneNumberRegex)) {
            return helpers.withMessage('Veuillez entrer un numéro de téléphone français valide.', required)
          }
        }
      }
    }
  },
  computed: {
    getSheetManageType() {
      return apiRegistrationStore().getSheetManageType;
    },
    getSheetId() {
      return apiRegistrationStore().getSheetId;
    }
  },
  created() {
    if(this.getSheetManageType === 1) {
      this.lastnameInput = this.getSheetId.lastname;
      this.firstnameInput = this.getSheetId.firstname;
      this.birthdayInput = this.getSheetId.birthday;
      this.phoneInput = this.getSheetId.phone;
    }
  },
  mounted() {
    this.isMobile = this.$vuetify.display.mdAndUp;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    async callPostSheet() {
      try {
        const response = await apiRegistrationStore().callPostSheet(this.lastnameInput, this.firstnameInput, this.birthdayInput, this.phoneInput);
        if (response) {
          generalStore().showNotifSnackbar("Création de la fiche !", "green", true, 5000);
          this.$router.push("/inscription/fiche");
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    async callPutSheet() {
      try {
        const response = await apiRegistrationStore().callPutSheet(this.getSheetId.id, this.lastnameInput, this.firstnameInput, this.birthdayInput, this.phoneInput);
        if (response) {
          generalStore().showNotifSnackbar("Modification de la fiche !", "green", true, 5000);
          this.$router.push("/inscription/fiche");
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    goTo(url) {
      this.$router.options.history.state.back ? this.$router.go(-1) : this.$router.push(url);
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>