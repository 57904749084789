<template>
  <v-navigation-drawer :temporary="true" v-model="getStateNavbar" color="blue-grey-darken-4">
    <v-divider></v-divider>
    <v-list class="pa-0">
      <v-list-item prepend-icon="mdi-home" title="Accueil" @click="goTo('/accueil')"></v-list-item>
    </v-list>
    <v-divider></v-divider>
    <div class="position-relative py-1 cursor-pointer title-over">
      <h3 class="text-center" @click.stop="showRegistration()">Inscription</h3>
      <v-btn color="white" @click.stop="showRegistration()" style="position: absolute; top: 0; right: 0" class="mr-2 mt-1" :icon="registrationShowIcon" size="small" density="comfortable" variant="plain"></v-btn>
    </div>
    <v-divider></v-divider>
    <v-list class="pa-0" v-if="registrationShow">
      <v-list-item prepend-icon="mdi-calendar-check" title="Voir les activités" @click="goTo('/inscription/activite')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item prepend-icon="mdi-account-group" title="Voir les fiches" @click="goTo('/inscription/fiche')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item prepend-icon="mdi-help-box" title="Besoin d'aide" @click="goTo('/inscription/aide')"></v-list-item>
    </v-list>
    <v-divider v-if="registrationShow"></v-divider>
    <div class="position-relative py-1 cursor-pointer title-over">
      <h3 class="text-center" @click.stop="showTools()">Outils</h3>
      <v-btn color="white" @click.stop="showTools()" style="position: absolute; top: 0; right: 0" class="mr-2 mt-1" :icon="toolsShowIcon" size="small" density="comfortable" variant="plain"></v-btn>
    </div>
    <v-divider></v-divider>
    <v-list class="pa-0" v-if="toolsShow">
      <v-list-item prepend-icon="mdi-qrcode" title="Création QRCode" @click="goTo('/outils/qrcode')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item prepend-icon="mdi-clock-time-eight" title="Calculateur Horaire" @click="goTo('/outils/calcul-horaire')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item prepend-icon="mdi-merge" title="PDF Fusion" @click="goTo('/outils/pdf-fusion')"></v-list-item>
      <v-divider></v-divider>
      <v-list-item prepend-icon="mdi-test-tube" title="Beta test" @click="goTo('/outils/dev')"></v-list-item>
      <v-divider></v-divider>
    </v-list>
    <v-divider></v-divider>
    <div class="position-absolute text-center w-100 mb-2" style="bottom:0">
      <p class="">Nous sommes le {{ date }}</p>
      <p class="">Il est {{ time }}</p>
    </div>
  </v-navigation-drawer>
</template>
<script>
import {defineComponent} from 'vue';
import {generalStore} from "@/store/General/generalStore";

export default defineComponent({
  name: 'NavComponent',
  data() {
    return {
      registrationShow: false,
      registrationShowIcon: "mdi-chevron-right",
      toolsShow: false,
      toolsShowIcon: "mdi-chevron-right",
      interval: null,
      time: "00:00:00",
      date: new Date().toLocaleDateString()
    }
  },
  computed: {
    getStateNavbar() {
      return generalStore().getStateNavbar;
    }
  },
  created() {
    this.interval = setInterval(() => {
      this.date = new Date().toLocaleDateString();
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000);
    document.addEventListener('click', this.detectClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.detectClick);
  },
  methods: {
    showTools() {
      this.toolsShow = !this.toolsShow;
      this.toolsShow ? this.toolsShowIcon = "mdi-chevron-down" : this.toolsShowIcon = "mdi-chevron-right"
    },
    showRegistration() {
      this.registrationShow = !this.registrationShow;
      this.registrationShow ? this.registrationShowIcon = "mdi-chevron-down" : this.registrationShowIcon = "mdi-chevron-right"
    },
    goTo(url) {
      generalStore().toogleNavbar();
      this.$router.push(url);
    },
    toggleNavbar() {
      generalStore().toogleNavbar();
    },
    detectClick(event) {
      if (event.target.classList.contains('v-navigation-drawer__scrim')) {
        generalStore().toogleNavbar();
      }
    }
  }
});
</script>
<style scoped>
.title-over:hover {
  background: #2f3a40;
}
</style>