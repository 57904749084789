<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="bg-grey-lighten-4">
          <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between align-content-center align-center">
              <v-btn prepend-icon="mdi-arrow-left-bold" @click="goTo('/inscription/activite')" class="mr-2" color="blue-grey-darken-4" variant="flat">Retour Activités</v-btn>
              <!--<v-breadcrumbs :items="['Foo', 'Bar', 'Fizz']"></v-breadcrumbs>-->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
                <v-row>
                  <v-col
                      cols="12"
                      md="12"
                  >
                    <v-text-field
                        hide-details
                        :clearable="true"
                        v-model="nameInput" :error-messages="v$.nameInput.$errors.map(e => e.$message)" @input="v$.nameInput.$touch" @blur="v$.nameInput.$touch"
                        required
                    >
                      <template v-slot:label>
                        <div class="custom-label">
                          Nom de l'activité<span class="text-red-darken-1">*</span>
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        hide-details
                        v-model="dateActivityStartInput"
                        type="datetime-local"
                        required
                    >
                      <template v-slot:label>
                        <div class="custom-label">
                          Date de Début<span class="text-red-darken-1">*</span>
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        hide-details
                        v-model="dateActivityEndInput"
                        type="datetime-local"
                        required
                    >
                      <template v-slot:label>
                        <div class="custom-label">
                          Date de Fin<span class="text-red-darken-1">*</span>
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-checkbox v-model="priceCheckbox" @update:modelValue="priceInput = null">
                      <template v-slot:label>
                        <div class="custom-label">
                          L'activité est-elle payante ? <span style="font-size: 12px;">(Si oui cochez la case)</span>
                        </div>
                      </template>
                    </v-checkbox>
                    <v-row>
                      <v-col
                          cols="12"
                          md="12"
                      >
                        <v-text-field
                            :disabled="!priceCheckbox"
                            label="Indiquer le prix non adhérent"
                            type="number"
                            step="0.10"
                            v-model="priceInput" :error-messages="v$.priceInput.$errors.map(e => e.$message)" @input="v$.priceInput.$touch" @blur="v$.priceInput.$touch"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-checkbox v-model="priceMemberCheckbox" @update:modelValue="priceMemberInput = null">
                          <template v-slot:label>
                            <div class="custom-label">
                              L'activité à t-elle un prix adhérent ? <span style="font-size: 12px;">(Si oui cochez la case)</span>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                            :disabled="!priceMemberCheckbox"
                            label="Indiquer le prix adhérent"
                            type="number"
                            step="0.10"
                            v-model="priceMemberInput" :error-messages="v$.priceMemberInput.$errors.map(e => e.$message)" @input="v$.priceMemberInput.$touch" @blur="v$.priceMemberInput.$touch"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-checkbox
                        v-model="seatCheckbox"
                        @update:modelValue="seatInput = null"
                        label="L'activité à t'elle un nombre de place réservé ?"
                        hint="Si oui cochez la case"
                        persistent-hint
                    >
                    </v-checkbox>
                    <v-row>
                      <v-col
                          cols="12"
                          md="12"
                      >
                        <v-text-field
                            :disabled="!seatCheckbox"
                            label="Indiquer le nombre de place"
                            type="number"
                            step="1"
                            v-model="seatInput" :error-messages="v$.seatInput.$errors.map(e => e.$message)" @input="v$.seatInput.$touch" @blur="v$.seatInput.$touch"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
            </v-col>
          </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between align-content-center align-center">
                <v-btn prepend-icon="mdi-close-thick" @click="goTo('/inscription/activite')" color="red-darken-1" variant="flat">Annuler</v-btn>
                <v-btn v-if="getActivityManageType === 0" icon-inner="mdi-plus" @click="createActivity()" class="mr-2" prepend-icon="mdi-creation" color="green-darken-1" variant="flat">Créer</v-btn>
                <v-btn v-if="getActivityManageType === 1" icon-inner="mdi-plus" @click="editActivity()" class="mr-2" prepend-icon="mdi-creation" color="green-darken-1" variant="flat">Modifier</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {helpers, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {apiRegistrationStore} from "@/store/Registration/apiRegistrationStore";
import {generalStore} from "@/store/General/generalStore";

export default {
  name: 'ActivityManageView',
  data() {
    return {
      isMobile: false,
      nameInput: null,
      dateActivityStartInput: null,
      dateActivityEndInput: null,
      priceCheckbox: false,
      priceInput: null,
      priceMemberCheckbox: false,
      priceMemberInput: null,
      seatCheckbox: false,
      seatInput: null,
    };
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    const isNumber = (value) => {
      if (typeof value === 'undefined') return true; // Laisser passer les champs vides
      return !isNaN(parseFloat(value)) && isFinite(value);
    };

    return {
      nameInput: {
        required: helpers.withMessage('Le nom est requis.', required)
      },
      priceInput: {
        required: helpers.withMessage('Le prix est requis.', required),
        isNumber: helpers.withMessage('Le prix doit être un nombre à virgule avec deux décimales (20.19 par exemple)', isNumber),
        isPriceRequired() {
          return !this.priceCheckbox;
        }
      },
      seatInput: {
        required: helpers.withMessage('Le prix est requis.', required),
        isPriceRequired() {
          return !this.seatInput;
        }
      },
      priceMemberInput: {
        required: helpers.withMessage('Le prix adhérent est requis.', required)
      },
    }
  },
  computed: {
    getActivityManageType() {
      return apiRegistrationStore().getActivityManageType;
    },
    getActivityId() {
      return apiRegistrationStore().getActivityId;
    }
  },
  created() {
    if (this.getActivityManageType === 1) {
      this.nameInput = this.getActivityId.name;
      this.dateActivityStartInput = this.getActivityId.dateActivityStart;
      this.dateActivityEndInput = this.getActivityId.dateActivityEnd;
      if (this.getActivityId.seat != null) {
        this.seatCheckbox = true;
      }
      if (this.getActivityId.price != null) {
        this.priceCheckbox = true;
      }
      if (this.getActivityId.priceMember != null) {
        this.priceMemberCheckbox = true;
      }
      this.seatInput = this.getActivityId.seat;
      this.priceInput = this.getActivityId.priceInput;
      this.priceMemberInput = this.getActivityId.priceMember;
    } else {
      this.nameInput = null;
      this.dateActivityStartInput = null;
      this.dateActivityEndInput = null;
      this.seatInput = null;
      this.priceInput = null;
      this.priceMemberInput = null;
      this.priceMemberCheckbox = false;
      this.priceCheckbox = false;
      this.seatCheckbox = false;
    }
  },
  mounted() {
    this.isMobile = this.$vuetify.display.mdAndUp;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    async createActivity() {
      try {
        const response = await apiRegistrationStore().callPostActivity(this.nameInput, this.dateActivityStartInput, this.dateActivityEndInput, this.priceInput, this.priceMemberInput, this.seatInput);
        if (response) {
          generalStore().showNotifSnackbar("Création de l'activité !", "green", true, 5000);
          this.$router.push("/inscription/activite");
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    async editActivity() {
      try {
        const response = await apiRegistrationStore().callPutActivity(this.getActivityId.id, this.nameInput, this.dateActivityStartInput, this.dateActivityEndInput, this.priceInput, this.priceMemberInput, this.seatInput);
        if (response) {
          generalStore().showNotifSnackbar("Modification de la fiche !", "green", true, 5000);
          this.$router.options.history.state.back ? this.$router.go(-1) : this.$router.push("/inscription/activity");
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
    },
    goTo(url) {
      this.$router.push(url);
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>