import {defineStore} from 'pinia'
import axios from "axios";
import {authStore} from "@/store/General/authStore";
import {generalStore} from "@/store/General/generalStore";

export const apiRegistrationStore = defineStore('apiRegistrationStore', {
    state: () => {
        return {
            activity: [],
            activityId: [],
            sheet: [],
            sheetId: [],
            registration: [],
            activityManageType: 0, //0 Add Activity | 1 Edit Activity
            sheetManageType: 0, //0 Add Sheet | 1 Edit Sheet
        }
    },
    getters: {
        getActivity: (state) => {
            return state.activity;
        },
        getActivityId: (state) => {
            return state.activityId;
        },
        getSheet: (state) => {
            return state.sheet;
        },
        getSheetId: (state) => {
            return state.sheetId;
        },
        getRegistration: (state) => {
            return state.registration;
        },
        getActivityManageType: (state) => {
            return state.activityManageType;
        },
        getSheetManageType: (state) => {
            return state.sheetManageType;
        },
    },
    actions: {
        async callGetActivity() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_ACTIVITY,{
                    headers: { Authorization: 'Bearer ' + authStore().getToken }
                });
                this.activity = response.data;
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callGetActivityId(id) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_ACTIVITY + "/" + id,{
                    headers: { Authorization: 'Bearer ' + authStore().getToken }
                });
                this.activityId = response.data;
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callDeleteActivity(id) {
            try {
                const response = await axios.delete(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_ACTIVITY + '/' + id,{
                    headers: { Authorization: 'Bearer ' + authStore().getToken }
                });
                console.log(response);
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callPutActivity(id,name,dateActivityStart,dateActivityEnd,price,priceMember,seat) {
            try {
                await axios.put(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_ACTIVITY + "/" + id, {
                        'name': name,
                        'price': parseFloat(price),
                        'priceMember': parseFloat(priceMember),
                        'seat': parseInt(seat),
                        'dateActivityStart': formatDate(new Date(dateActivityStart)),
                        'dateActivityEnd': formatDate(new Date(dateActivityEnd))
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + authStore().getToken,
                            'Content-Type': 'application/json'
                        }
                    });
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callPostActivity(name,dateActivityStart,dateActivityEnd,price,priceMember,seat) {
            try {
                await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_ACTIVITY, {
                        'name': name,
                        'price': parseFloat(price),
                        'priceMember': parseFloat(priceMember),
                        'seat': parseInt(seat),
                        'dateActivityStart': formatDate(new Date(dateActivityStart)),
                        'dateActivityEnd': formatDate(new Date(dateActivityEnd))
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + authStore().getToken,
                            'Content-Type': 'application/json'
                        }
                    });
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callGetSheet() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_SHEET,{
                    headers: { Authorization: 'Bearer ' + authStore().getToken }
                });
                this.sheet = response.data;
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callGetSheetId(id) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_SHEET + "/" + id,{
                    headers: { Authorization: 'Bearer ' + authStore().getToken }
                });
                this.sheetId = response.data;
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callDeleteSheet(id) {
            try {
                const response = await axios.delete(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_SHEET + '/' + id,{
                    headers: { Authorization: 'Bearer ' + authStore().getToken }
                });
                console.log(response);
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callGetRegistration(id) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_REGISTRATION + "/" + id,{
                    headers: { Authorization: 'Bearer ' + authStore().getToken }
                });
                this.registration = response.data;
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callPostRegistration(idActivity, idSheet) {
            try {
                await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_REGISTRATION,
                    {
                        'activity': idActivity,
                        'sheet': idSheet,
                    },
                    {
                    headers: { Authorization: 'Bearer ' + authStore().getToken }
                });
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callDeleteRegistration(idRegistration) {
            try {
                await axios.delete(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_REGISTRATION + "/" + idRegistration,
                    {
                        headers: { Authorization: 'Bearer ' + authStore().getToken }
                    });
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callPutRegistration(idRegistration, payement) {
            try {
                await axios.put(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_REGISTRATION + "/" + idRegistration,
                    {
                        'payement': payement
                    }
                    ,{
                        headers: { Authorization: 'Bearer ' + authStore().getToken }
                    });
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callPostSheet(lastname, firstname, birthday, phone) {
            try {
                /*const response = */await axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_SHEET,{
                    'firstname': firstname,
                    'lastname': lastname,
                    'birthday': birthday,
                    'phone': phone
                },{
                    headers: {
                        Authorization: 'Bearer ' + authStore().getToken }
                });
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
        async callPutSheet(id, lastname, firstname, birthday, phone) {
            try {
                /*const response = */await axios.put(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_REGISTRATION_SHEET + "/" + id,{
                    'firstname': firstname,
                    'lastname': lastname,
                    'birthday': birthday,
                    'phone': phone
                },{
                    headers: {
                        Authorization: 'Bearer ' + authStore().getToken }
                });
                return true;
            } catch(error) {
                handleApiError(error);
                return false;
            }
        },
    }
})

export function handleApiError(error) {
    if (error.response) {
        if(error.response.status === 409) {
            generalStore().showNotifSnackbar("Erreur la donnée est déjà présente dans la base de donnée !", "red", true, 5000);
        } else if(error.response.status === 401) {
            authStore().apiLogout();
            generalStore().showNotifSnackbar("Votre session d'authentification a expiré ! Veuillez vous reconnecter !", "red", true, 5000);
        } else if(error.response.status === 403) {
            generalStore().showNotifSnackbar("Erreur vous n'avez pas la permission !", "red", true, 5000);
        } else if(error.response.status === 404) {
            generalStore().showNotifSnackbar("La ressource n'existe pas ou n'existe plus !", "red", true, 5000);
        } else {
            generalStore().showNotifSnackbar("Une erreur est survenue, veuillez réessayer !", "red", true, 5000);
        }
    } else if (error.request) {
        generalStore().showNotifSnackbar("Erreur le serveur ne répond pas !", "red", true, 5000);
    } else {
        generalStore().showNotifSnackbar("Erreur lors de la requête !", "red", true, 5000);
    }
}

export function formatDate(date) {
    const [year, month, day, hours, minutes] = [
        date.getFullYear(),
        String(date.getMonth() + 1).padStart(2, '0'),
        String(date.getDate()).padStart(2, '0'),
        String(date.getHours()).padStart(2, '0'),
        String(date.getMinutes()).padStart(2, '0')
    ];
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}
