<template>
  <v-app v-if="!isLoginPage">
    <TopbarComponent />
    <NavComponent />
    <v-layout class="h-100 py-16 bg-blue-grey-darken-1">
      <router-view></router-view>
    </v-layout>
    <FooterComponent />
  </v-app>
  <v-app v-else class="bg-blue-grey-darken-1">
    <router-view></router-view>
  </v-app>
  <SnackbarComponent />
</template>

<script>
import TopbarComponent from "@/components/General/TopbarComponent.vue";
import NavComponent from "@/components/General/NavComponent.vue";
import FooterComponent from "@/components/General/FooterComponent.vue";
import SnackbarComponent from "@/components/General/SnackbarComponent.vue";

export default {
  name: 'App',
  components: {SnackbarComponent, NavComponent, FooterComponent, TopbarComponent},
  computed: {
    isLoginPage() {
      return this.$route.name === 'Connexion' || this.$route.name === 'Erreur 404' || this.$route.name === 'Erreur 403' || this.$route.name === 'Erreur 500';
    }
  },
}
</script>