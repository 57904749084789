<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table-virtual
            :headers="headersSheet"
            :items="filteredSheet"
            class="elevation-1"
            items-per-page="-1"
            :hover="true"
            :loading="stateLoadDataSheet"
            @click:row="goToSheet"
        >
          <template v-slot:loading>
            <v-icon class="text-h2 mt-5" icon="mdi-download-network"></v-icon>
            <p class="text-h4 mb-5" v-if="getSheet.length <= 0">Récupérations des fiches...</p>
            <p class="text-h4 mb-5" v-else>Actualisation des fiches...</p>
          </template>
          <template v-slot:no-data>
            <v-icon class="text-h2 mt-5" icon="mdi-emoticon-cry-outline"></v-icon>
            <p class="text-h4 mb-5">Aucune fiche</p>
            <v-btn prepend-inner-icon="mdi-plus" class="mb-5" density="comfortable" color="green-darken-1" variant="flat" disable="true">Créer</v-btn>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex flex-column d-md-flex flex-md-row">
              <v-btn icon="mdi-pencil" density="comfortable" color="orange-darken-1" @click.stop="editSheet(item)"></v-btn>
              <v-btn icon="mdi-delete" density="comfortable" class="ml-0 mt-1 ml-md-2 mt-md-0" @click.stop="openDialogBoxDeleteSheetConfirm(item)" color="red-darken-1"></v-btn>
            </div>
          </template>
          <template v-slot:top>
            <v-row class="pa-2 bg-grey-lighten-4" :no-gutters="true" v-if="isMobile">
              <v-col cols="12" class="mb-2 d-flex justify-space-between align-content-center align-center">
                <v-btn icon="mdi-plus" @click="goTo('/inscription/fiche/creation')" class="mr-2" density="comfortable" color="green-darken-1" variant="flat"></v-btn>
                <v-text-field v-model="searchSheet" prepend-inner-icon="mdi-magnify" label="Rechercher une fiche" single-line hide-details clearable="true"></v-text-field>
                <v-btn icon="mdi-refresh" @click="refreshActivity()" :loading="stateLoadRefresh" :disable="stateLoadRefresh" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
                <v-btn :icon="iconFilter" @click="openFilter()" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
                <v-btn icon="mdi-printer" @click="print" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" disabled="true"></v-btn>
                <v-btn icon="mdi-download" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" disabled="true"></v-btn>
              </v-col>
              <v-col cols="12" v-if="isOpenFilter" class="mb-2 d-flex justify-center align-content-center align-center">
                <v-checkbox hide-details label="Date de modification" v-model="filterCheckbox.dateModified" @update:modelValue="filterColumn()"></v-checkbox>
                <v-checkbox hide-details label="Date de création" v-model="filterCheckbox.dateCreated" @update:modelValue="filterColumn()"></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="pa-2 bg-grey-lighten-4" :no-gutters="true" v-else>
              <v-col cols="12" class="mb-2 d-flex justify-space-between">
                <v-btn icon="mdi-plus" @click="goTo('/inscription/fiche/creation')" density="comfortable" color="green-darken-1" variant="flat"></v-btn>
                <v-btn icon="mdi-refresh" @click="refreshActivity()" :loading="stateLoadRefresh" :disable="stateLoadRefresh" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
                <v-btn :icon="iconFilter" @click="openFilter()" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
                <v-btn icon="mdi-printer" @click="print" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" disabled="true"></v-btn>
                <v-btn icon="mdi-download" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" disabled="true"></v-btn>
              </v-col>
              <v-col cols="6" v-if="isOpenFilter" class="mb-2 d-flex flex-column justify-center align-content-start align-start">
                <v-checkbox hide-details label="Date de modification" v-model="filterCheckbox.dateModified" @update:modelValue="filterColumn()"></v-checkbox>
                <v-checkbox hide-details label="Date de création" v-model="filterCheckbox.dateCreated" @update:modelValue="filterColumn()"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="searchSheet" prepend-inner-icon="mdi-magnify" label="Rechercher" single-line hide-details clearable="true"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.modifiedAt`]="{ item }">
            {{ new Date(item.modifiedAt).toLocaleString() }}
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ new Date(item.createdAt).toLocaleString() }}
          </template>
          <template v-slot:[`item.birthday`]="{ item }">
            <p v-if="item.birthday !== null">{{ calculateAge(item.birthday) }} ans ({{item.birthday}})<v-icon color="amber" icon="mdi-cake-variant" v-if="isBirthday(item.birthday)"></v-icon>

            </p>
            <p v-else>Non renseigné</p>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <p v-if="item.phone !== null">{{item.phone}}</p>
            <p v-else>Non renseigné</p>
          </template>
          <template v-slot:bottom></template>
        </v-data-table-virtual>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="dialogBoxDeleteSheet" max-width="400">
    <v-card>
      <v-card-title class="headline">Confirmer la suppression</v-card-title>
      <v-card-text>
        En supprimant cette fiche, les inscriptions aux activité presents seront aussi supprimé ! Êtes-vous sûr de vouloir supprimer la fiche
        {{ this.she }}" ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" @click="dialogBoxDeleteSheet = false">Annuler</v-btn>
        <v-btn color="green darken-1" @click="callDeleteSheet">Confirmer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {apiRegistrationStore} from "@/store/Registration/apiRegistrationStore";
import {generalStore} from "@/store/General/generalStore";

export default {
  name: 'SheetView',
  data() {
    return {
      isMobile: false,
      stateLoadDataSheet: false,
      stateLoadRefresh: false,
      stateLoadFilter: false,
      headersSheet: [
        { title: 'Nom', align: 'start', sortable: true, key: 'lastname'},
        { title: 'Prénom', align: 'start', key: 'firstname', sortable: true},
        { title: 'Naissance', align: 'start', key: 'birthday', sortable: true},
        { title: 'Phone', align: 'start', key: 'phone', sortable: false},
        { title: '', align: 'start', key: 'action', sortable: false},
      ],
      searchSheet: null,
      isOpenFilter: false,
      iconFilter: 'mdi-filter',
      filterCheckbox: {
        dateModified: false,
        dateCreated: false
      },
      dialogBoxDeleteSheet: false,
      selectedSheetDelete: null,

    };
  },
  computed: {
    filteredSheet() {
      const searchTerm = this.searchSheet ? this.searchSheet.toLowerCase() : '';
      if (Array.isArray(apiRegistrationStore().getSheet)) {
        return apiRegistrationStore().getSheet.filter(event =>
            event.lastname.toLowerCase().includes(searchTerm)
        );
      } else {
        return [];
      }
    },
    getSheet() {
      return apiRegistrationStore().getSheet;
    }
  },
  mounted() {
    this.isMobile = this.$vuetify.display.mdAndUp;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    goToSheet(item, row) {
      this.$router.push("/inscription/fiche/" + row.item.id);
      window.scrollTo(0, 0);
    },
    isBirthday(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);
      return today.getMonth() === birthDate.getMonth() && today.getDate() === birthDate.getDate();
    },
    calculateAge(birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    async callGetSheet() {
      this.stateLoadRefresh = true;
      if(apiRegistrationStore().getSheet.length <= 0) {
        this.stateLoadDataSheet = true;
      }
      try {
        await apiRegistrationStore().callGetSheet();
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
      this.stateLoadRefresh = false;
      this.stateLoadDataSheet = false;
    },
    async callDeleteSheet() {
      try {
        const response = await apiRegistrationStore().callDeleteSheet(this.selectedSheetDelete.id);
        if (response) {
          generalStore().showNotifSnackbar("Suppression de la fiche !", "green", true, 5000);
          this.dialogBoxDeleteSheet = false;
          this.selectedSheetDelete = null;
        }
      } catch (error) {-
        console.error("Une erreur s'est produite", error);
      }
      this.callGetSheet();
    },
    openDialogBoxDeleteSheetConfirm(activitySelected) {
      this.dialogBoxDeleteSheet = true;
      this.selectedSheetDelete = activitySelected;
    },
    refreshActivity() {
      this.stateLoadDataSheet = true;
      this.callGetSheet();
    },
    filterColumn() {
      this.headersSheet = [
        { title: 'Nom', align: 'start', sortable: true, key: 'lastname'},
        { title: 'Prénom', align: 'start', key: 'firstname', sortable: true},
        { title: 'Naissance', align: 'start', key: 'birthday', sortable: true},
        { title: 'Phone', align: 'start', key: 'phone', sortable: false},
      ];
      if (this.filterCheckbox.dateModified) {
        this.headersSheet.push({ title: 'Dernière Modification', align: 'center', key: 'modifiedAt', sortable: true });
      }
      if (this.filterCheckbox.dateCreated) {
        this.headersSheet.push({ title: 'Crée le', align: 'center', key: 'createdAt', sortable: true });
      }
      this.headersSheet.push({ title: '', align: 'start', key: 'action', sortable: false});
    },
    openFilter() {
      this.isOpenFilter = !this.isOpenFilter;
      this.isOpenFilter ? this.iconFilter = "mdi-filter-off" : this.iconFilter = "mdi-filter";
    },
    handleResize() {
      this.isMobile = this.$vuetify.display.mdAndUp;
    },
    editSheet(sheet) {
      apiRegistrationStore().sheetSelectEdit = sheet;
      this.$router.push("/inscription/fiche/modification/" + sheet.id);
    },
    goTo(url) {
      this.$router.push(url);
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style scoped>
:deep(.v-data-table__td) {
  padding: 0 5px !important;
}
@media screen and (max-width: 959px) {
  :deep(.v-data-table__td) {
    font-size: 10px;
  }
  :deep(.v-chip) {
    font-size: 10px;
  }
  :deep(.v-label) {
    font-size: 10px;
  }
}
</style>