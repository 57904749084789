<template>
  <v-container>
    <table border="1">
      <thead>
      <tr>
        <th rowspan="2">Semaine 9</th>
          <th colspan="2">test</th>
          <th rowspan="2">Nb Heures</th>
      </tr>
      <tr>
          <th>Matin</th>
          <th>Après-Midi</th>
      </tr>
      </thead>
      <tbody>
        <tr>
          <td>Lundi 25 Juin</td>
          <td>8h45-12h00</td>
          <td>8h45-12h00</td>
          <td>1:00</td>
        </tr>
      <tr>
        <td>Lundi 06 mai</td>
        <td>aazaswsazez</td>
        <td></td>
        <td>1:00</td>

      </tr>
      </tbody>
    </table>



    <!--<table border="1">
      <thead>
      <tr>
        <th rowspan="2">Semaine {{ this.planning[1].planning[0].semaine }}</th>
        <template v-for="(structure, index) in this.planning[0].planning[0].structure" :key="index">
          <th colspan="2">{{ structure.name }}</th>
          <th rowspan="2">Nb Heures</th>
        </template>
      </tr>
      <tr>
        <template v-for="(structure, index) in this.planning[0].planning[0].structure" :key="index">
          <th>Matin</th>
          <th>Midi</th>
        </template>
      </tr>
      </thead>
      <tbody>
      <template v-for="(structure, index) in this.planning[0].planning[0].structure" :key="index">
        <tr>
          {{index}}
          <td>{{getSpecificDay(this.planning[1].planning[0].semaine,"2024",getKeyByValue(structure, structure.monday))}}</td>
          <td>8h45-12h00</td>
          <td>8h45-12h00</td>
          <td>1:00</td>
          <td>8h45-12h00</td>
          <td>8h45-12h00</td>
          <td>1:00</td>
          <td>8h45-12h00</td>
          <td>8h45-12h00</td>
          <td>1:00</td>
        </tr>
      </template>
      <tr>
        <td>Lundi 06 mai</td>
        <td>8h45-12h00</td>
        <td>8h45-12h00</td>
        <td>1:00</td>
        <td>8h45-12h00</td>
        <td>8h45-12h00</td>
        <td>1:00</td>
        <td>8h45-12h00</td>
        <td>8h45-12h00</td>
        <td>1:00</td>
      </tr>
      </tbody>
    </table>-->
  </v-container>
</template>

<script>
export default {
  name: 'DevView',
  data() {
    return {
      planning: null,
      listStructures: [],

    }
  },
  methods: {
    getKeyByValue(object, data) {
      return Object.keys(object).find(key => object[key] === data);
    },
    getSpecificDay(numeroSemaine, annee, nomJour) {
      const year = parseInt(annee);
      const weekNumber = parseInt(numeroSemaine);
      const dayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      const day = dayNames.indexOf(nomJour.toLowerCase());

      const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
      const dayIndex = date.getDay();
      const diff = day - dayIndex + (dayIndex === 0 ? 7 : 0); // Handling Sunday

      const specificDate = new Date(date);
      specificDate.setDate(specificDate.getDate() + diff);

      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      const formattedDate = specificDate.toLocaleDateString('fr-FR', options);

      return formattedDate;
    }
  }
};
</script>
<style scoped>
</style>