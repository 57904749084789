<template>
  <v-footer class="bg-blue-grey-darken-4 w-100 ma-0 pa-0 d-flex flex-column" style="z-index:998;">
    <v-row class="pa-2" no-gutters>
      <v-col cols="12" class="d-flex flex-column align-center align-content-center justify-center">
        <p>{{ new Date().getFullYear() }} - <strong>Ville Plurielle ©</strong></p>
        <p class="text-caption">Développeur <a class="text-light-blue-darken-3" href="https://harmant-mathieu.fr/" target="_blank">Mathieu HARMANT</a></p>
      </v-col>
      <v-col cols="12" class="d-flex flex-row align-center align-content-center justify-center">
        <p class="mr-1 text-capitalize">Version {{nameVersion}}</p>
        <img @click="easterEgg()" width="30" height="30" :src="imgVersion"/>
        <p class="ml-1">{{ numberVersion }}</p>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import {defineComponent} from 'vue';
import Pokedex from "pokedex";

export default defineComponent({
  name: 'FooterComponent',
  mounted() {
    var Pokedex = require('pokedex'), pokedex = new Pokedex();
    this.imgVersion = pokedex.pokemon(parseInt(process.env.VUE_APP_NUM_POKEMON)).sprites.animated;
    this.numberVersion = process.env.VUE_APP_VERSION
    this.nameVersion = process.env.VUE_APP_NAME_VERSION
  },
  data: () => ({
    imgVersion: null,
    numberVersion: null,
    nameVersion: null,
    counterClick: 0,
    easterEggFinish: true
  }),
  methods: {
    easterEgg() {
      if(this.counterClick >= 10 && this.easterEggFinish === true) {
        this.counterClick = 0;
        this.easterEggFinish = false;
        setTimeout(() => {
          let firstTimer = setInterval(this.createPokemonFall, 500)
          setTimeout(() => {
            clearInterval(firstTimer);
          }, 63000);
        }, 1000);
        setTimeout(() => {
          let secondTimer = setInterval(this.createPokemonFall, 50)
          setTimeout(() => {
            clearInterval(secondTimer);
          }, 28800);
        }, 34200);
        const audio = new Audio(require('@/assets/music/pokemon_generique.mp3'));
        audio.play();
        setTimeout(() => {
          audio.pause();
          audio.currentTime = 0;
          this.easterEggFinish = true;
        }, 64200);
      } else {
        this.counterClick++;
      }
    },
    createPokemonFall() {
      const pokemonFall = document.createElement('img');
      pokemonFall.classList.add('pokemonFall');
      pokemonFall.style.transform = "rotate(" +  Math.random() * 360 + "deg)"
      pokemonFall.style.left = Math.random() * 100 + "vw";
      pokemonFall.style.fontSize = (Math.random() * (20 - 10) + 10) + "px";
      pokemonFall.style.animationDuration = Math.random() * 2 + 3 + "s";
      let pokedex = new Pokedex();
      let min = Math.ceil(1);
      let max = Math.floor(649);
      pokemonFall.src = pokedex.pokemon(Math.floor(Math.random() * (max - min) + min)).sprites.animated;
      document.body.appendChild(pokemonFall);
      setTimeout(() => {
        pokemonFall.remove();
      }, 5000);
    },
  }
});
</script>
<style>
.pokemonFall {
  position: fixed;
  top: -20vh;
  z-index: 9999;
  font-size: 2rem;
  transform: translateY(0);
  animation: fall 3s linear forwards;
}
@keyframes fall {
  to {
    transform: translateY(105vh);
  }
}
</style>