<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table-virtual
          :headers="headersActivity"
          :items="filteredActivity"
          class="elevation-1"
          items-per-page="-1"
          :hover="true"
          :loading="stateLoadDataActivity"
          @click:row="goToRegistration"
        >
          <template v-slot:loading>
            <v-icon class="text-h2 mt-5" icon="mdi-download-network"></v-icon>
            <p class="text-h4 mb-5" v-if="getActivity.length <= 0">Récupérations des activités...</p>
            <p class="text-h4 mb-5" v-else>Actualisation des activités...</p>
          </template>
          <template v-slot:no-data>
            <v-icon class="text-h2 mt-5" icon="mdi-emoticon-cry-outline"></v-icon>
            <p class="text-h4 mb-5">Aucun évènement</p>
            <v-btn prepend-inner-icon="mdi-plus" class="mb-5" density="comfortable" color="green-darken-1" variant="flat" disable="true">Créer</v-btn>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex flex-column d-md-flex flex-md-row justify-center align-content-center align-center">
              <v-chip v-if="isDateBetween(item.dateActivityStart, item.dateActivityEnd)" color="orange-darken-1" size="small">En cours</v-chip>
              <v-chip v-else-if="isDateAfter(item.dateActivityEnd)" color="green-darken-1" size="small">Terminé</v-chip>
              <v-chip v-else color="blue-darken-1" size="small">A venir</v-chip>
              <v-chip v-if="item.registration >= item.seat && item.seat != null" color="red-darken-1" size="small">Complet</v-chip>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex flex-column d-md-flex flex-md-row">
              <v-btn icon="mdi-pencil" density="comfortable" color="orange-darken-1" @click.stop="goTo('/inscription/activite/modification/' + item.id)"></v-btn>
              <v-btn icon="mdi-delete" density="comfortable" class="ml-0 mt-1 ml-md-2 mt-md-0" @click.stop="openDialogBoxDeleteActivityConfirm(item)" color="red-darken-1"></v-btn>
              <v-btn v-if="isDateAfter(item.dateActivityEnd)" icon="mdi-lock-open" density="comfortable" class="ml-0 mt-1 ml-md-2 mt-md-0" color="grey-darken-1"></v-btn>
            </div>
          </template>
          <template v-slot:[`item.registration`]="{ item }">
            <div v-if="item.seat !== null">
              <span class="font-weight-bold" :class="{
                'text-red': item.seat - item.registration <= 0,
                'text-orange': item.seat - item.registration <= 3 & item.seat - item.registration > 0,
                'text-amber-lighten-2': item.seat - item.registration <= 5 && item.seat - item.registration > 3,
                'text-green': item.seat - item.registration > 5}"
              >
                {{ item.registration }}
              </span>
              <span class="font-weight-bold"> / {{ item.seat }}</span>
            </div>
            <div class="font-weight-bold text-green-lighten-1" v-else>
              {{ item.registration }}
            </div>
          </template>
          <template v-slot:top>
            <v-row class="pa-2 bg-grey-lighten-4" :no-gutters="true" v-if="isMobile">
              <v-col cols="12" class="mb-2 d-flex justify-space-between align-content-center align-center">
                <v-btn icon="mdi-plus" @click="goTo('/inscription/activite/creation')" class="mr-2" density="comfortable" color="green-darken-1" variant="flat"></v-btn>
                <v-text-field v-model="searchActivity" class="w-25 mr-2" prepend-inner-icon="mdi-magnify" label="Rechercher une activité" single-line hide-details clearable="true"></v-text-field>
                <v-select prepend-inner-icon="mdi-home-group" hide-details single-line :items="['Tous', 'Maison de Quartier Gare', 'Maison de Quartier Bois de la Sarre', 'Maison de Quartier Grande Fin', 'Hometoons', 'Les Petites Grenouilles', 'Les Diablotins', 'Les Petites Frimousses', 'Les Petits Crocos','Ville Plurielle']"></v-select>
                <v-btn icon="mdi-refresh" @click="refreshActivity()" :loading="stateLoadRefresh" :disable="stateLoadRefresh" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
                <v-btn :icon="iconFilter" @click="openFilter()" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
                <v-btn icon="mdi-printer" @click="print" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" disabled="true"></v-btn>
                <v-btn icon="mdi-download" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" disabled="true"></v-btn>
              </v-col>
              <v-col cols="12" v-if="isOpenFilter" class="mb-2 d-flex justify-space-between align-content-center align-center">
                <v-checkbox hide-details label="Date de clôture" v-model="filterCheckbox.dateClose" @update:modelValue="filterColumn()"></v-checkbox>
                <v-checkbox hide-details label="Prix adhérent" v-model="filterCheckbox.priceMember" @update:modelValue="filterColumn()"></v-checkbox>
                <v-checkbox hide-details label="Prix non adhérent" v-model="filterCheckbox.priceNonMember" @update:modelValue="filterColumn()"></v-checkbox>
                <v-checkbox hide-details label="Date de modification" v-model="filterCheckbox.dateModified" @update:modelValue="filterColumn()"></v-checkbox>
                <v-checkbox hide-details label="Date de création" v-model="filterCheckbox.dateCreated" @update:modelValue="filterColumn()"></v-checkbox>
              </v-col>
            </v-row>
            <v-row class="pa-2 bg-grey-lighten-4" :no-gutters="true" v-else>
              <v-col cols="12" class="mb-2 d-flex justify-space-between">
                <v-btn icon="mdi-plus" @click="goTo('/inscription/activite/creation')" density="comfortable" color="green-darken-1" variant="flat"></v-btn>
                <v-btn icon="mdi-refresh" @click="refreshActivity()" :loading="stateLoadRefresh" :disable="stateLoadRefresh" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
                <v-btn :icon="iconFilter" @click="openFilter()" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat"></v-btn>
                <v-btn icon="mdi-printer" @click="print" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" disabled="true"></v-btn>
                <v-btn icon="mdi-download" class="ml-2" density="comfortable" color="blue-grey-darken-4" variant="flat" disabled="true"></v-btn>
              </v-col>
              <v-col cols="6" v-if="isOpenFilter" class="mb-2 d-flex flex-column justify-start align-content-start align-start">
                <v-checkbox hide-details label="Date de clôture" v-model="filterCheckbox.dateClose" @update:modelValue="filterColumn()"></v-checkbox>
                <v-checkbox hide-details label="Prix adhérent" v-model="filterCheckbox.priceMember" @update:modelValue="filterColumn()"></v-checkbox>
              </v-col>
              <v-col cols="6" v-if="isOpenFilter" class="mb-2 d-flex flex-column justify-start align-content-start align-start">
                <v-checkbox hide-details label="Prix non adhérent" v-model="filterCheckbox.priceNonMember" @update:modelValue="filterColumn()"></v-checkbox>
                <v-checkbox hide-details label="Date de modification" v-model="filterCheckbox.dateModified" @update:modelValue="filterColumn()"></v-checkbox>
                <v-checkbox hide-details label="Date de création" v-model="filterCheckbox.dateCreated" @update:modelValue="filterColumn()"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="searchActivity" prepend-inner-icon="mdi-magnify" label="Rechercher" single-line hide-details clearable="true"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <p v-if="item.price === null && item.priceMember === null">Gratuit</p>
            <p v-if="item.priceMember !== null && item.price === null">0€</p>
            <p v-if="item.price !== null">{{item.price}}€</p>
          </template>
          <template v-slot:[`item.priceMember`]="{ item }">
            <p v-if="item.price === null && item.priceMember === null">Gratuit</p>
            <p v-if="item.price !== null && item.priceMember === null">0€</p>
            <p v-if="item.priceMember !== null">{{item.priceMember}}€</p>
          </template>
          <template v-slot:[`item.dateActivityClosing`]="{ item }">
            <p v-if="item.dateActivityClosing !== null">{{ new Date(item.dateActivityClosing).toLocaleString() }}</p>
            <p v-else>Non cloturé</p>
          </template>
          <template v-slot:[`item.dateActivityStart`]="{ item }">
            {{ new Date(item.dateActivityStart).toLocaleString() }}
          </template>
          <template v-slot:[`item.dateActivityEnd`]="{ item }">
            {{ new Date(item.dateActivityEnd).toLocaleString() }}
          </template>
          <template v-slot:[`item.modifiedAt`]="{ item }">
            {{ new Date(item.modifiedAt).toLocaleString() }}
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ new Date(item.createdAt).toLocaleString() }}
          </template>
          <template v-slot:bottom></template>
        </v-data-table-virtual>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="dialogBoxDeleteActivity" max-width="400">
    <v-card>
      <v-card-title class="headline">Confirmer la suppression</v-card-title>
      <v-card-text>
        Êtes-vous sûr de vouloir supprimer l'activité "TODONAME HERE" ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" @click="dialogBoxDeleteActivity = false">Annuler</v-btn>
        <v-btn color="green darken-1" @click="callDeleteActivity">Confirmer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {apiRegistrationStore} from "@/store/Registration/apiRegistrationStore";
import {generalStore} from "@/store/General/generalStore";

export default {
  name: 'ActivityView',
  data() {
    return {
      isMobile: false,
      stateLoadDataActivity: false,
      stateLoadRefresh: false,
      stateLoadFilter: false,
      headersActivity: [
        { title: 'Status', align: 'center', key: 'status', sortable: false},
        { title: 'Nom', align: 'start', sortable: true, key: 'name'},
        { title: 'Inscrit(s)', align: 'start', key: 'registration', sortable: true},
        { title: 'Date de Début', align: 'start', key: 'dateActivityStart', sortable: true},
        { title: 'Date de Fin', align: 'start', key: 'dateActivityEnd', sortable: true},
        { title: '', align: 'start', key: 'action', sortable: false},
      ],
      searchActivity: null,
      isOpenFilter: false,
      iconFilter: 'mdi-filter',
      filterCheckbox: {
        dateClose: false,
        dateEnd: false,
        priceMember: false,
        priceNonMember: false,
        dateModified: false,
        dateCreated: false
      },
      dialogBoxDeleteActivity: false,
      selectedActivityDelete: null,

    };
  },
  computed: {
    filteredActivity() {
      const searchTerm = this.searchActivity ? this.searchActivity.toLowerCase() : '';
      if (Array.isArray(apiRegistrationStore().getActivity)) {
        return apiRegistrationStore().getActivity.filter(event =>
            event.name.toLowerCase().includes(searchTerm)
        );
      } else {
        return [];
      }
    },
    getActivity() {
      return apiRegistrationStore().getActivity;
    }
  },
  created() {
    this.callGetActivity();
  },
  mounted() {
    this.isMobile = this.$vuetify.display.mdAndUp;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    async callGetActivity() {
      this.stateLoadRefresh = true;
      if(apiRegistrationStore().getActivity.length <= 0) {
        this.stateLoadDataActivity = true;
      }
      try {
        const response = await apiRegistrationStore().callGetActivity();
        if (response) {
          generalStore().showNotifSnackbar("Actualisation des activités effectuées !", "green", true, 5000);
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
      this.stateLoadRefresh = false;
      this.stateLoadDataActivity = false;
    },
    async callDeleteActivity() {
      try {
        const response = await apiRegistrationStore().callDeleteActivity(this.selectedActivityDelete.id);
        if (response) {
          generalStore().showNotifSnackbar("Suppression de l'activité !", "green", true, 5000);
        }
      } catch (error) {
        console.error("Une erreur s'est produite", error);
      }
      this.callGetActivity();
    },
    openDialogBoxDeleteActivityConfirm(activitySelected) {
      this.dialogBoxDeleteActivity = true;
      this.selectedActivityDelete = activitySelected;
    },
    refreshActivity() {
      this.stateLoadDataActivity = true;
      this.callGetActivity();
    },
    filterColumn() {
      this.headersActivity = [
        { title: 'Status', align: 'center', key: 'status', sortable: false},
        { title: 'Nom', align: 'start', sortable: true, key: 'name'},
        { title: 'Inscrit(s)', align: 'start', key: 'seat', sortable: true},
        { title: 'Date de Début', align: 'start', key: 'dateActivityStart', sortable: true},
        { title: 'Date de Fin', align: 'start', key: 'dateActivityEnd', sortable: true},
      ];
      if (this.filterCheckbox.priceMember) {
        this.headersActivity.push({ title: 'Prix non Adhérent', align: 'center', key: 'priceMember', sortable: true });
      }
      if (this.filterCheckbox.priceNonMember) {
        this.headersActivity.push({ title: 'Prix Adhérent', align: 'center', key: 'price', sortable: true });
      }
      if (this.filterCheckbox.dateEnd) {
        this.headersActivity.push({ title: 'Date de Fin', align: 'center', key: 'dateEventEnd', sortable: true });
      }
      if (this.filterCheckbox.dateClose) {
        this.headersActivity.push({ title: 'Date de Clôture', align: 'center', key: 'dateActivityClosing', sortable: true });
      }
      if (this.filterCheckbox.dateModified) {
        this.headersActivity.push({ title: 'Dernière Modification', align: 'center', key: 'modifiedAt', sortable: true });
      }
      if (this.filterCheckbox.dateCreated) {
        this.headersActivity.push({ title: 'Crée le', align: 'center', key: 'createdAt', sortable: true });
      }
      this.headersActivity.push({title: '', align: 'start', key: 'action', sortable: false});
    },
    openFilter() {
      this.isOpenFilter = !this.isOpenFilter;
      this.isOpenFilter ? this.iconFilter = "mdi-filter-off" : this.iconFilter = "mdi-filter";
    },
    isDateBetween(startDate, endDate) {
      const currentDateMs = Date.now();
      const startDateMs = Date.parse(startDate);
      const endDateMs = Date.parse(endDate);
      return currentDateMs >= startDateMs && currentDateMs <= endDateMs;
    },
    isDateAfter(endDate) {
      const currentDateMs = Date.now();
      const endDateMs = Date.parse(endDate);
      return currentDateMs > endDateMs;
    },
    handleResize() {
      this.isMobile = this.$vuetify.display.mdAndUp;
    },
    goTo(url) {
      this.$router.push(url);
    },
    goToRegistration(item, row) {
      this.$router.push("/inscription/activite/" + row.item.id);
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style scoped>
:deep(.v-data-table__td) {
  padding: 0 5px !important;
}
@media screen and (max-width: 959px) {
  :deep(.v-data-table__td) {
    font-size: 10px;
  }
  :deep(.v-chip) {
    font-size: 10px;
  }
  :deep(.v-label) {
    font-size: 10px;
  }
}
</style>