import {defineStore} from 'pinia'

export const generalStore = defineStore('generalStore', {
    state: () => {
        return {
            stateNavbar: false,
            navbarShowIcon: "mdi-menu",
            snackbars: [],
        }
    },
    getters: {
        getStateNavbar: (state) => {
            return state.stateNavbar;
        },
        getNavbarShowIcon: (state) => {
            return state.navbarShowIcon;
        }
    },
    actions: {
        toogleNavbar() {
            this.stateNavbar = !this.stateNavbar;
            this.stateNavbar ? this.navbarShowIcon = "mdi-menu-open" : this.navbarShowIcon = "mdi-menu";
        },
        async showNotifSnackbar(message = "", color = 'blue', show = true, timeout = 5000) {
            const snackbar = {
                id: Date.now(), // Génère un identifiant unique pour chaque snackbar
                message,
                color,
                show,
                timeout,
            };
            this.snackbars.push(snackbar);
            setTimeout(() => {
                this.hideSnackbar(snackbar.id);
            }, timeout);
        },
        hideSnackbar(id) {
            this.snackbars = this.snackbars.filter(snackbar => snackbar.id !== id);
        },
    }
})