import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "@/views/General/LoginView.vue";
import QRCodeView from "@/views/Tools/QRCodeView.vue";
import HourlyCalculationView from "@/views/Tools/HourlyCalculationView.vue";
import Error404View from "@/views/Errors/Error404View.vue";
import {authStore} from "@/store/General/authStore";
import HomeView from "@/views/HomeView.vue";
import ProfileView from "@/views/Profile/ProfileView.vue";
import ActivityView from "@/views/Registration/ActivityView.vue";
import RegistrationView from "@/views/Registration/RegistrationView.vue";
import SheetView from "@/views/Registration/SheetView.vue";
import ActivityManageView from "@/views/Registration/ActivityManageView.vue";
import SheetManageView from "@/views/Registration/SheetManageView.vue";
import {apiRegistrationStore} from "@/store/Registration/apiRegistrationStore";
import SheetInfoView from "@/views/Registration/SheetInfoView.vue";
import DevView from "@/views/Tools/DevView.vue";
import PdfMerge from "@/views/Tools/PdfMerge.vue";

const routes = [
    {
        path: '/',
        name: 'Connexion',
        component: LoginView,
        beforeEnter: (to, from, next) => {
            if (authStore().getConnected) {
                next('/accueil');
            } else {
                next();
            }
        }
    },
    {
        path: '/profil',
        name: 'Profil',
        component: ProfileView,
        beforeEnter: (to, from, next) => {
            if (authStore().getConnected) {
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/accueil',
        name: 'Accueil',
        component: HomeView,
        beforeEnter: (to, from, next) => {
            if (authStore().getConnected) {
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/inscription/activite',
        name: 'Listes des Activités',
        component: ActivityView,
        beforeEnter: (to, from, next) => {
            if (authStore().getConnected) {
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/inscription/activite/:id',
        name: 'Inscription Activité',
        component: RegistrationView,
        beforeEnter: async (to, from, next) => {
            if (authStore().getConnected) {
                try {
                    await apiRegistrationStore().callGetRegistration(to.params.id);
                } catch (error) {
                    console.error("Une erreur s'est produite", error);
                    next('/');
                }
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/inscription/activite/creation',
        name: 'Création Activité',
        component: ActivityManageView,
        beforeEnter: (to, from, next) => {
            if (authStore().getConnected) {
                apiRegistrationStore().activityManageType = 0;
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/inscription/activite/modification/:id',
        name: 'Modification Activité',
        component: ActivityManageView,
        beforeEnter: async (to, from, next) => {
            if (authStore().getConnected) {
                apiRegistrationStore().activityManageType = 1;
                try {
                    await apiRegistrationStore().callGetActivityId(to.params.id);
                } catch (error) {
                    console.error("Une erreur s'est produite", error);
                    next('/');
                }
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/inscription/fiche',
        name: 'Listes des Fiches',
        component: SheetView,
        beforeEnter: async (to, from, next) => {
            if (authStore().getConnected) {
                apiRegistrationStore().sheetManageType = 1;
                try {
                    await apiRegistrationStore().callGetSheet();
                } catch (error) {
                    console.error("Une erreur s'est produite", error);
                    next('/');
                }
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/inscription/fiche/creation',
        name: 'Création Fiche',
        component: SheetManageView,
        beforeEnter: (to, from, next) => {
            if (authStore().getConnected) {
                apiRegistrationStore().sheetManageType = 0;
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/inscription/fiche/modification/:id',
        name: 'Modification Fiche',
        component: SheetManageView,
        beforeEnter: async (to, from, next) => {
            if (authStore().getConnected) {
                apiRegistrationStore().sheetManageType = 1;
                try {
                    await apiRegistrationStore().callGetSheetId(to.params.id);
                } catch (error) {
                    console.error("Une erreur s'est produite", error);
                    next('/');
                }
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/inscription/fiche/:id',
        name: 'Infos Fiche',
        component: SheetInfoView,
        beforeEnter: async (to, from, next) => {
            if (authStore().getConnected) {
                try {
                    await apiRegistrationStore().callGetSheetId(to.params.id);
                } catch (error) {
                    console.error("Une erreur s'est produite", error);
                    next('/');
                }
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/outils/qrcode',
        name: 'QRCode',
        component: QRCodeView,
        beforeEnter: (to, from, next) => {
            if (authStore().getConnected) {
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/outils/calcul-horaire',
        name: 'Calcul Horaire',
        component: HourlyCalculationView,
        beforeEnter: (to, from, next) => {
            if (authStore().getConnected) {
                next()
            } else {
                next('/');
            }
        }
    },
    {
        path: '/outils/pdf-fusion',
        name: 'PDF Fusion',
        component: PdfMerge,
    },
    {
        path: '/outils/dev',
        name: 'Beta Test',
        component: DevView,
    },
    {
        path: '/:catchAll(.*)',
        name: 'Erreur 404',
        component: Error404View
    }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
