<template>
  <v-container>
    <h2>Vos informations</h2>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field label="Prénom"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Nom"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Email"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Téléphone pro"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" :loading="loading">
              Sauvegarder
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <h2>Sécurité du compte</h2>
    <v-row>
      <v-col cols="12">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Ancien mot de passe"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Nouveau mot de passe"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Confirmer nouveau mot de passe"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="loading">
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
      </v-col>
      </v-row>
    <h2>Double Authentification</h2>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Ancien mot de passe"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Nouveau mot de passe"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Confirmer nouveau mot de passe"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" :loading="loading">
              Sauvegarder
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProfileView',
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style scoped>
</style>